
import Routing from './Routing/Routing';

function App() {
  return (
  <div  style={{ fontFamily: "Amiri, sans-serif" }}>
    <Routing/>
  </div>
  );
}

export default App;
