import React, { useState } from "react";
import {
  AiOutlineMenu,
  AiOutlineClose,
  AiOutlineHome,
  AiOutlineInfoCircle,
  AiOutlineAppstore,
  AiOutlineTeam,
  AiOutlineAim,
  AiOutlinePhone,
} from "react-icons/ai";
import { Link } from "react-router-dom";
import logo from "../../assets/logo1.jpeg";

const Navbar = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [isEnglish, setIsEnglish] = useState(true);
  const [activeMenu, setActiveMenu] = useState("/");

  const menu = [
    { arabic: "الصفحة الرئيسية", english: "Home", link: "/", icon: <AiOutlineHome /> },
    { arabic: "من نحن", english: "About Us", link: "/about", icon: <AiOutlineInfoCircle /> },
    { arabic: "الخدمات", english: "Services", link: "/logistic", icon: <AiOutlineAppstore /> },
    { arabic: "لوجستي", english: "Logistic", link: "/services", icon: <AiOutlineAppstore /> },
    { arabic: "قيمنا", english: "Our Values", link: "/our-values", icon: <AiOutlineTeam /> },
    { arabic: "أهدافنا", english: "Our Goals", link: "/goals", icon: <AiOutlineAim /> },
    { arabic: "اتصل بنا", english: "Contact Us", link: "/contact-us", icon: <AiOutlinePhone /> },
  ];

  const handleMenuClick = (link) => {
    setActiveMenu(link);
    setMenuOpen(false); 
    window.scrollTo(0, 0); 
  };

  return (
    <nav className="text-black px-2 py-2 flex items-center justify-between bg-white md:px-8 font-bold border-b-2 border-orange-500 hover:text-orange-500 sticky top-0 z-50">
      <div className="flex-shrink-0 flex-1">
        <Link to="/">
          <img
            src={logo}
            alt="Logo"
            width={233.88}
            height={90}
            className="mt-0 w-[233.88px] h-[90px] bg-white"
          />
        </Link>
      </div>
         

      <button
        className="lg:hidden text-2xl text-black"
        onClick={() => setMenuOpen(!menuOpen)}
      >
        {menuOpen ? <AiOutlineClose /> : <AiOutlineMenu />}
      </button>

      <ul
        className={`${
          menuOpen ? "flex" : "hidden"
        } lg:flex flex-col lg:flex-row items-left gap-4 md:gap-8 absolute lg:static top-[100px] left-0 w-full lg:w-auto bg-white md:bg-transparent md:shadow-none z-10 px-6 md:px-0`}
      >
        {menu.map((item, index) => (
          <li
            key={index}
            className={`font-bold py-2 md:py-0 text-sm cursor-pointer transition duration-300 ease-in-out hover:p-4 hover:bg-orange-500 hover:text-white`}
            title={item.arabic}
            onClick={() => handleMenuClick(item.link)}
            style={{
              padding: activeMenu === item.link ? "1rem" : "0.5rem",
              backgroundColor: activeMenu === item.link ? "#FFA500" : "transparent",
              color: activeMenu === item.link ? "white" : "#4B5563",
            }}
          >
            <Link to={item.link} className="flex items-center gap-2 text-black">
              {item.icon}
              {isEnglish ? item.english : item.arabic}
            </Link>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default Navbar;
