import React from 'react';
import { FaPhone, FaEnvelope, FaGlobe } from 'react-icons/fa'; // Import icons
import { Box, Typography } from '@mui/material';
import { BiSolidPhoneCall } from 'react-icons/bi'; // Phone Icon
import logo from '../../assets/logo1.jpeg'; // Import your logo image here

const ContactInfo = () => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: 2, paddingLeft: '20px' }}>
      
      {/* Company Logo */}
      {/* <Box sx={{ mb: 2 }}>
        <img src={logo} alt="Company Logo" style={{ width: '100px', height: 'auto' }} />
      </Box> */}

      {/* Phone Icon and Text */}
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: 40,
            height: 40,
            borderRadius: '100%',
            backgroundColor: '#f97316', // Orange-500 color
            color: '#fff', // White text for icons
          }}
        >
          <BiSolidPhoneCall />
        </Box>
        <Typography variant="body1" sx={{ color: '#333', textAlign: 'left' }}>
          +966-11111111
        </Typography>
      </Box>

      {/* Email Icon and Text */}
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: 40,
            height: 40,
            borderRadius: '100%',
            backgroundColor: '#f97316', // Orange-500 color
            color: '#fff', // White text for icons
          }}
        >
          <FaEnvelope />
        </Box>
        <Typography variant="body1" sx={{ color: '#333', textAlign: 'left' }}>
          info@amc.com.sa
        </Typography>
      </Box>

      {/* Website Icon and Text */}
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: 40,
            height: 40,
            borderRadius: '100%',
            backgroundColor: '#f97316', // Orange-500 color
            color: '#fff', // White text for icons
          }}
        >
          <FaGlobe />
        </Box>
        <Typography variant="body1" sx={{ color: '#333', textAlign: 'left' }}>
          www.amc.com.sa
        </Typography>
      </Box>

      {/* Address Details */}
      <Box sx={{ mt: 3, textAlign: 'left', paddingLeft: '10px' }}>
        <Typography variant="body1" sx={{ color: '#333', fontWeight: 'bold' }}>
          Address:
        </Typography>
        <Typography variant="body2" sx={{ color: '#333', marginTop: 1 }}>
          8138 Abban Al Bajli, 4112 Al Ferdous Dist., Jeddah, Saudi Arabia, PO-23818O
        </Typography>
      </Box>
    </Box>
  );
};

export default ContactInfo;
