import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Navbar from "../componnet/Navbar/Navbar";
import Footer from "../componnet/Footer/Footer";

// Lazy-loaded components
const Hero = lazy(() => import("../componnet/Hero/Hero"));
const ServiceSection = lazy(() => import("../componnet/Services/Services"));
const Client = lazy(() => import("../componnet/client/Client"));
const AboutPage = lazy(() => import("../componnet/about/AboutPage"));
const OurValuePage = lazy(() => import("../componnet/ourValue/OurValuePage"));
const OurGoalsPage = lazy(() => import("../componnet/Goal/OurGoalsPage"));
const TestimonialSlider = lazy(() =>
  import("../componnet/Testimonial/Testimonial")
);
const ContactUs = lazy(() => import("../componnet/contact/ContactUs"));
const BlogPost = lazy(() => import("../componnet/Product/Product"));
const ServicePage = lazy(() => import("../componnet/Services/ServicePage"));
const ServicesDetailsPage = lazy(() =>
  import("../componnet/Services/ServicesDetailsPage")
);
const LogisticPage = lazy(() => import("../componnet/Logistic/LogisticPage"));
const LogisticDetais = lazy(() =>
  import("../componnet/Logistic/LogisticDetais")
);

// Loader Component
const Loader = () => {
  return (
    <div className="flex items-center justify-center min-h-screen">
      <div
        className="w-16 h-16 border-4 border-t-orange-500 border-gray-300 rounded-full animate-spin"
        role="status"
      ></div>
    </div>
  );
};

const Routing = () => {
  return (
    <Router>
      <div>
        <Navbar />
        {/* Suspense provides a fallback UI while the components are being loaded */}
        <Suspense fallback={<Loader />}>
          <Routes>
          <Route path="/" element={<Hero />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/services" element={<ServicePage />} />
          <Route path="/services/:serviceId" element={<ServicesDetailsPage />} />
          <Route path="/client" element={<Client />} />
          <Route path="/our-values" element={<OurValuePage/>} />
          <Route path="/goals" element={<OurGoalsPage />} />
          <Route path="/testimonial-slider" element={<TestimonialSlider />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/blog" element={<BlogPost />} />
          <Route path="/footer" element={<Footer />} />
          <Route path="/logistic" element={<LogisticPage />} />
          <Route path="/logistic/:id" element={<LogisticDetais />} />
          </Routes>
        </Suspense>
        <Footer />
      </div>
    </Router>
  );
};

export default Routing;
